// export const HOST = "http://localhost:8080"; 
export const HOST = "https://api.delicatoimeapp.com";  
// export const HOST = "https://ap1.imeapp.in";
// export const HOST = "https://api.assesseaseime.com";
export const TimeOut = 650;
export const PATIENT_LIST = "PATIENT_LIST"; 
export const PATIENTS_LIST = "PATIENTS_LIST"; 
export const SET_PATIENT_LIST = "SET_PATIENT_LIST";
export const SET_PATIENTS_LIST = "SET_PATIENTS_LIST";
export const SET_PATIENTS_LIST_DATA = "SET_PATIENTS_LIST_DATA";
// export const PATIENT_DATA = "PATIENT_DATA";
export const UPON_ARRIVED_DATA1 = "UPON_ARRIVED_DATA1";
export const SET_PATIENT_DATA = "SET_PATIENT_DATA";
export const HISTORY_DATA = "HISTORY_DATA";
export const SET_HISTORY_DATA = "SET_HISTORY_DATA"; 
export const PAST_INJURY_DATA = "PAST_INJURY_DATA";
export const SET_PAST_INJURY_DATA = "SET_PAST_INJURY_DATA";
export const OCCUPATIONAL_HISTORY_DATA = "OCCUPATIONAL_HISTORY_DATA";
export const SET_OCCUPATIONAL_HISTORY_DATA = "SET_OCCUPATIONAL_HISTORY_DATA";
export const CURRENT_MEDICATION_DATA = "CURRENT_MEDICATION_DATA";
export const SET_CURRENT_MEDICATION_DATA = "SET_CURRENT_MEDICATION_DATA";
export const GENERAL_OBSERVATION_DATA = "GENERAL_OBSERVATION_DATA";
export const SET_GENERAL_OBSERVATION_DATA = "SET_GENERAL_OBSERVATION_DATA";
export const TREATMENT_DATA = "TREATMENT_DATA";
export const SET_TREATMENT_DATA = "SET_TREATMENT_DATA";
export const CERVICAL_DATA = "CERVICAL_DATA";
export const SET_CERVICALDATA = "setcervicaldata";
export const CERVICALDATA_LIST = "cervicaldata_List";
export const SET_CERVICALPOST = "SET_CERVICALPOST";
export const SET_UPON_ARRIVED_DATA = "SET_UPON_ARRIVED_DATA";
export const UPON_ARRIVED_DATA = "UPON_ARRIVED_DATA";
export const FILE_UPLOAD_DATA = "FILE_UPLOAD_DATA";
export const ALLFILE_UPLOAD_DATA = "ALLFILE_UPLOAD_DATA";
export const SET_FILE_UPLOAD_DATA = "SET_FILE_UPLOAD_DATA";
export const DELETE_FILE_UPLOAD_DATA = "DELETE_FILE_UPLOAD_DATA";
export const DOCTOR_ASSISTANT = "DOCTOR_ASSISTANT";
export const SET_DOCTOR_ASSISTANT = "SET_DOCTOR_ASSISTANT";
export const SET_PRIOR_EXAM = "SET_PRIOR_EXAM";
export const PRIOR_EXAM = "PRIOR_EXAM";
export const DELETE_DATA = "DELETE_DATA";
export const DELETE_DATA_SRG = "DELETE_DATA_SRG";
export const DELETE_DATA_DIAG = "DELETE_DATA_DIAG";
export const DELETE_FILE_DATA = "DELETE_FILE_DATA";
export const DELETE_FILE_DATA1 = "DELETE_FILE_DATA1";
export const DELETE_FILE_DATA2 = "DELETE_FILE_DATA2";
export const LUMBOSACRAL_DATA = "lumbosacral_data";
export const LUMBOSACRAL_GET = "lumbosacralget";
export const SET_LUMBOSACRAL = "set_lumbosacral";
export const SET_CERVICAL = "SET_CERVICAL";
export const SET_CERVICAL_LIST = "SET_CERVICAL_LIST";
export const QUESTIONNAIRE = "QUESTIONNAIRE";
export const SET_QUESTIONNAIRE = "SET_QUESTIONNAIRE";
export const THORACICDATA_LIST = "THORACICDATA_LIST";
export const THORACIC_DATA = "THORACIC_DATA";
export const SET_THORACICDATA = "SET_THORACICDATA";
export const HEADDATA_LIST = "HEADDATA_LIST";
export const HEAD_DATA = "HEAD_DATA";
export const SET_HEADDATA = "SET_HEADDATA";
export const FOOT_DATA_LIST = "FOOT_DATA_LIST";
export const FOOT_DATA = "FOOT_DATA";
export const SET_FOOT_DATA = "SET_FOOT_DATA";
export const R_FOOT_DATA_LIST = "R_FOOT_DATA_LIST";
export const R_FOOT_DATA = "R_FOOT_DATA";
export const R_SET_FOOT_DATA = "R_SET_FOOT_DATA";
export const RIGHTLEG_DATA = "RIGHTLEG_DATA";
export const SET_RIGHTLEG_DATA = "SET_RIGHTLEG_DATA";
export const RIGHTLEG_POSTDATA = "RIGHTLEG_POSTDATA";
export const PATIENTSCHEDULER_DATA = "PATIENTSCHEDULER_DATA";
export const SET_PATIENTSCHEDULER_DATA = "SET_PATIENTSCHEDULER_DATA";
export const SHOULDERDATA_LIST = "SHOULDERDATA_LIST";
export const SHOULDER_DATA = "SHOULDER_DATA";
export const SET_SHOULDERDATA = "SET_SHOULDERDATA";
export const LSHOULDERDATA_LIST = "LSHOULDERDATA_LIST";
export const LSHOULDER_DATA = "LSHOULDER_DATA";
export const LSET_SHOULDERDATA = "LSET_SHOULDERDATA";
export const LEFT_LEG_DATA = "LEFT_LEG_DATA";
export const SET_LEFT_LEG_DATA = "SET_LEFT_LEG_DATA";
export const LEFT_LEG_DATA_POST = "LEFT_LEG_DATA_POST";
export const RIGHT_ANKLE_DATA = "RIGHT_ANKLE_DATA";
export const SET_RIGHT_ANKLE_DATA = "SET_RIGHT_ANKLE_DATA";
export const DELETE_FILE_UPLOAD_DATA_ALL = "DELETE_FILE_UPLOAD_DATA_ALL";
export const RIGHT_ANKLE_DATA_POST = "RIGHT_ANKLE_DATA_POST";
export const LEFT_ANKLE_DATA = "LEFT_ANKLE_DATA";
export const SET_LEFT_ANKLE_DATA = "SET_LEFT_ANKLE_DATA";
export const LEFT_ANKLE_DATA_POST = "LEFT_ANKLE_DATA_POST";
export const CHEST_DATA = "CHEST_DATA";
export const SET_CHEST_DATA = "SET_CHEST_DATA";
export const CHEST_DATA_LIST = "CHEST_DATA_LIST";
export const RWRISTDATA_LIST = "RWRISTDATA_LIST";
export const RWRIST_DATA = "RWRIST_DATA";
export const SET_RWRISTDATA = "SET_RWRISTDATA";
export const LWRISTDATA_LIST = "LWRISTDATA_LIST";
export const LWRIST_DATA = "LWRIST_DATA";
export const SET_LWRISTDATA = "SET_LWRISTDATA";
export const RHANDDATA_LIST = "RHANDDATA_LIST";
export const RHAND_DATA = "RHAND_DATA";
export const SET_RHANDDATA = "SET_RHANDDATA";
export const SET_RHAND_LIST = "SET_RHAND_LIST";
export const LHANDDATA_LIST = "LHANDDATA_LIST";
export const LHAND_DATA = "LHAND_DATA";
export const SET_LHANDDATA = "SET_LHANDDATA";
export const SET_LHAND_LIST = "SET_LHAND_LIST";
export const DOCTORUPON = "DOCTORUPON";
export const FILEBUTTON = "FILEBUTTON";
export const SET_FILEBUTTON = " SET_FILEBUTTON";
export const CREATE_USER = "CREATE_USER";
export const SET_CREATE_USER = "SET_CREATE_USER";
export const CONCLUSION_UPLOAD = "CONCLUSION_UPLOAD";
export const CLAIMANT_FORMDATA ="CLAIMANT_FORMDATA"
export const SET_CONCLUSION_UPLOAD = "SET_CONCLUSION_UPLOAD";
export const GET_CONCLUSION_UPLOAD = "GET_CONCLUSION_UPLOAD";
export const SET_GET_CONCLUSION_UPLOAD = "SET_GET_CONCLUSION_UPLOAD";
export const DELETE_CONCLUSION_UPLOAD = "DELETE_CONCLUSION_UPLOAD";
export const NORMAL_EXAM = "NORMAL_EXAM";
export const NORMAL_SELECT_EXAM = "NORMAL_SELECT_EXAM";
export const TREATMENT_EXAM_DATA = "TREATMENT_EXAM_DATA";
export const SET_TREATMENT_EXAM = "SET_TREATMENT_EXAM";
export const TREATMENT_DELETE_DATA = "TREATMENT_DELETE_DATA";
export const PASTINJURY_DELETE_DATA = "PASTINJURY_DELETE_DATA";
export const CONTRALATERAL_DATA = "CONTRALATERAL_DATA";
export const GENERATE_ONLINE_LINK = "GENERATE_ONLINE_LINK";
export const CANCEL_ONLINE_LINK = "CANCEL_ONLINE_LINK";
export const INTAKE_DATA = "INTAKE_DATA";
export const DELETE_QUESTIONNAIRE = "DELETE_QUESTIONNAIRE";
export const UPONARIVAL_DELETE_DATA ="UPONARIVAL_DELETE_DATA"
export const DOCTOR_SIGNATURE ="DOCTOR_SIGNATURE"
export const DOCTOR_FILEUPLOAD = "DOCTOR_FILEUPLOAD"
export const DELETE_DOCTOR_FILEUPLOAD = "DELETE_DOCTOR_FILEUPLOAD"
export const CLAIMANT_LIST = "CLAIMANT_LIST"
export const GET_CLAIMANT_LIST = "GET_CLAIMANT_LIST"
export const GET_PATIENTS_LIST = "GET_PATIENTS_LIST"
export const SET_ALL_PATIENTS_LIST = "SET_ALL_PATIENTS_LIST"
export const SET_COPY_CONTRALATERAL_LIST = "SET_COPY_CONTRALATERAL_LIST"
export const COPY_CONTRALATERAL_DATA = "COPY_CONTRALATERAL_DATA"